import { Link } from "gatsby";
import styled from "styled-components";

export const LinkTo = styled(Link)`
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;

`
export const Path = styled.div`
    display: flex;
`;
export const LinkElement = styled.div`
    display: flex;
    align-items: center;
    ${LinkTo}{
        color: ${({islink}) => (islink ? '#545454' : '#808080')};
        cursor: ${({islink}) => (islink ? 'pointer' : 'default')};
        pointer-events: ${({islink}) => (islink ? 'auto' : 'none')};
    }
    ${LinkTo}:hover{
        text-decoration: ${({islink}) => (islink ? 'underline' : 'none')};
    }
`;