import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import RequestDemo from "../components/RequestDemo/RequestDemo";

import Favicon from "../images/Favicon.png";

const RequestDemoPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Request Demo - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Experience Drivosity's innovative last-mile delivery solutions firsthand. Request a demo to learn how our real-time GPS tracking and car toppers can optimize safety, productivity, and same-day delivery efficiency. Reach out now."/>
            </Helmet>
            <Layout component={<RequestDemo/>}/>
        </>
    )
}

export default RequestDemoPage;
