import React, { useState } from 'react';

import DrvCover from "../Shared/DrvCover/DrvCover";
import DrvContent from "../Shared/DrvContent/DrvContent";
import { DrvLinkPath } from "../Shared/DrvLink/DrvLinkPath";
import { DrvInput } from "../Shared/DrvInput/DrvInput";
import { TextArea, SubmitButton , ErrorMessage, SupportImages } from "./RequestDemo.styles";
import { AiOutlineArrowRight } from 'react-icons/ai';

import {RequestDemoData} from "./RequestDemo.data";

import RequestDemoImage from "../../images/RequestDemo/Cover.png";
import safe from "../../images/RequestDemo/Safe.svg";
import fast from "../../images/RequestDemo/FastDelivery.svg";
import support from "../../images/RequestDemo/Support.svg"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { request } from '../../services/api';

import PhoneInput, {formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input';

const RequestDemo = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailSend, setEmailSend] = useState(false);
    const [reqError, setReqError] = useState(false);
    const [reqErrorMsg, setReqErrorMsg] = useState('');
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        company: '',
        phone: '',
        message: '',
    });
    const defaultErrors = {
        name: null,
        email: null,
        company: null,
        phone: null,
    };
    const [errors, setErrors] = useState(defaultErrors);
    // const validateNumber = (evt) => {
    //     var theEvent = evt || window?.event;
    //     setErrors({
    //         ...errors,
    //         [evt.target.name] : false
    //     })
    //     // Handle paste
    //     if (theEvent.type === "paste") {
    //         key = theEvent.clipboardData.getData("text/plain");
    //     } else {
    //         // Handle key press
    //         var key = theEvent.keyCode || theEvent.which;
    //         key = String.fromCharCode(key);
    //     }
    //     var regex = /[0-9]|\./;
    //     if (!regex.test(key)) {
    //         theEvent.returnValue = false;
    //         if (theEvent.preventDefault) theEvent.preventDefault();
    //     }
    // };
    const inputChange = (e) =>{
        const {name, value} = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: false
        })
    }
    const phoneNumberChange = (e) => {
        setInputs({
            ...inputs,
            phone: e
        });
        setErrors({
            ...errors,
            phone: false
        })
    }
    const validateField = (name, value) =>{
        switch (name){
            case "name":
                if(!value){
                    return true;
                }
                break;
            case "phone":
                if(!value){
                    return true;
                }
                if(!isValidPhoneNumber(value)){
                    return true;
                }
                break;
            case "company":
                if(!value){
                    return true;
                }
                break;
            case "email":
                if(!value){
                    return true;
                }
                else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
                    return true;
                }
                break;
            default:
                break;
        }
    }
    const submit = async (e) =>{
        e.preventDefault();
        if(!executeRecaptcha){
            return
        }
        const result = await executeRecaptcha();
        let errorObj = {};
        setErrors(defaultErrors);
        let errorHandler = false;
        Object.entries(inputs).forEach(([item,val]) =>{
            if(typeof validateField(item, val)){
                if(validateField(item, val)){
                    errorObj = {...errorObj, [item]: validateField(item, val)};
                    errorHandler = true;
                }
            }
        })
        setErrors(errorObj);
        if(!errorHandler){
            let reqInputs = {
                captcha_token: result,
                first_name:inputs.name,
                phone_number: formatPhoneNumberIntl(inputs.phone),
                company: inputs.company,
                email: inputs.email,
                message: inputs.message
            }
            try {
                const response = await request('post','/email/demo', reqInputs);
                if(response.success){
                    setEmailSend(true);
                }else{
                    setReqError(true);
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            } catch (err) {
                console.error(err);
                setReqError(true);
                if(typeof err.message === 'string'){
                    setReqErrorMsg(err.message);
                } else {
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            }
        }
    }
    return (
        <>
            <DrvCover
                title={RequestDemoData[0].title}
                description=''
                color='#ffffff'
                image={RequestDemoImage}
            />
            {
                !emailSend && (
                    <DrvContent
                        type={['text']}
                        content={[
                            (
                                <>
                                    <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: 'Request a Demo', link: '#', isLink: false }]} />
                                    <div className="drv-title" style={{fontSize: '30px', marginTop: '35px', lineHeight: '0', marginBottom: '60px'}}>
                                        REQUEST DEMO
                                    </div>
                                    <div className="flex flex-wrap ">
                                        <div className="flex basis-full md:basis-1/2 flex-col pr-0 md:pr-2">
                                            <DrvInput  type="text" className={errors.name ? "error" : ""}  value={inputs.name} onChange={inputChange} name="name" placeholder="Full Name *"/>
                                            <DrvInput  type="email" className={errors.email ? "error" : ""} value={inputs.email} onChange={inputChange} name="email" placeholder="Email *"/>
                                            <DrvInput  type="text" className={errors.company ? "error" : ""} value={inputs.company} onChange={inputChange} name="company" placeholder="Company *"/>
                                        </div>
                                        <div className="flex basis-full md:basis-1/2 flex-col pl-0 md:pl-2">
                                            <PhoneInput
                                                className={`${errors.phone ? "error" : ""} mb-[20px]`}
                                                international
                                                defaultCountry="US"
                                                limitMaxLength={true}
                                                value={inputs.phone}
                                                onChange={phoneNumberChange}
                                                placeholder='Phone Number *'
                                            />

                                            <TextArea placeholder="Message" value={inputs.message} onChange={inputChange} name="message"></TextArea>
                                        </div>
                                        <div className="flex basis-full md:basis-1/2">
                                        </div>
                                        <div className="flex basis-full md:basis-1/2 pl-0 md:pl-2">
                                            <SubmitButton className="basis-full justify-center items-center" onClick={submit}>SUBMIT
                                                <AiOutlineArrowRight style={{fontSize: "15px"}} className="ml-2" />
                                            </SubmitButton>
                                        </div>
                                        <div className="flex basis-full">
                                        {
                                            (errors.name || errors.email || errors.company) && (
                                                <ErrorMessage>
                                                    Validation errors occurred. Please confirm the fields and submit it again.
                                                </ErrorMessage>
                                            )
                                        }
                                        {
                                            (!errors.name && !errors.email && !errors.company) && errors.phone && (
                                                <ErrorMessage>
                                                    Phone number filed can not be empty or phone number is not valid. Please enter a valid phone number.
                                                </ErrorMessage>
                                            )
                                        }
                                        {
                                            (reqError) && (
                                                <ErrorMessage>
                                                    {reqErrorMsg}
                                                </ErrorMessage>
                                            )
                                        }
                                        </div>
                                    </div>
                                </>
                            )
                        ]}
                    >
                    </DrvContent>
                )
            }
            {
                emailSend &&
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <>
                                <div className="drv-title">
                                    Email Sent Successfully
                                </div>
                            </>
                        )
                    ]}
                    background="#f2f2f2"
                />
            }
            <SupportImages>
                <div style={{backgroundColor: "#0f6faa"}} className="flex basis-full flex-col lg:basis-1/3 justify-center">
                    <img src={safe} alt='Safe & Secure' style={{width: '67px', marginBottom: '10px'}}/>
                    SAFE & SECURE
                </div>
                <div style={{backgroundColor: "#0f6498"}} className="flex basis-full flex-col lg:basis-1/3 justify-center">
                    <img src={fast} alt='Fast Delivery' style={{width: '67px', marginBottom: '10px'}}/>

                    FAST DELIVERY
                </div>
                <div style={{backgroundColor: "#0e5888"}} className="flex basis-full flex-col lg:basis-1/3 justify-center">
                    <img src={support} alt='24/7 Support' style={{width: '67px', marginBottom: '10px'}}/>
                    24/7 SUPPORT
                </div>
            </SupportImages>
        </>
    )
}

export default RequestDemo;
