import styled from "styled-components";

export const TextArea = styled.textarea`
    width: 100%;
    height: calc(100% - 77px);
    padding: 10px 16px 9px;
    border: 1px solid #e6e6e6;
    outline: none;
    color: #a6a6a6;
    &:focus{
        outline: 2px solid #0e6faa !important;
    }
`;

export const SubmitButton = styled.button`
    display: flex;
    width: 100%;
    justify-content: center;
    background: #fff;
    white-space: nowrap;
    border:2px solid var(--primary-color);
    padding: 4px 35px;
    color: #545454;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    min-width: 100px;
    line-height: 38px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s !important;
    border-radius: 50px;
    align-items: center;
    &:hover{
    background: var(--primary-color);
    border-color :#fff;
    color: #fff;
    }
`;

export const ErrorMessage = styled.div`
    background-color: #ffd9d9;
    color: #f00;
    margin: 30px 0 10px;
    padding: 18px 30px 18px 20px;
    font-size: 14px;
    border: none;
    width: 100%;
`;

export const SupportImages = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    div{
        height: 250px;
        align-items: center;
    }
`;