import React from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { LinkTo, Path, LinkElement } from './DrvLinkPath.styles';

export const DrvLinkPath = (props) => {
    let a = props.items;
    return (
        <Path>
            {
                a.map((item, index) =>(
                    <LinkElement key={index} islink={item.isLink}>
                        <LinkTo to={item.link}>{item.text}</LinkTo>
                        {
                            index !== (a.length -1) &&
                            <MdOutlineKeyboardArrowRight className="ml-3 mr-3" style={{color: '#808080'}}/>
                        }
                    </LinkElement>
                ))
            }
        </Path>
    )
}
